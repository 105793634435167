import React from 'react'
import Img from 'gatsby-image'

const StaffDetails = ({staffInfo}) => {
    return (
        <div className="staff-details">
            <div className="staff-details__container row">
                <div className="left-column small-12 medium-3">
                    {(staffInfo.photo != null) ? <Img fluid={staffInfo.photo.localFile.childImageSharp.fluid} /> : <img className="image-placeholder" src="http://kakoon.dailypressgroup.org/content/uploads/2019/10/transparent-photo.png" alt="Placeholder"/>}
                </div>
                <div className="right-column small-12 medium-9">
                    <h2 dangerouslySetInnerHTML={{__html: `${staffInfo.name_and_title}`}}></h2>
                    <div dangerouslySetInnerHTML={{__html: `${staffInfo.description}`}}></div>
                </div>
            </div>
        </div>
    )
}

export default StaffDetails;