import React from "react"

const GravityFormField = ({fieldInfo}) => {
    switch(fieldInfo.type) {
        case "text":
            return <input key={fieldInfo.id} className="column small-12 text-field" type={fieldInfo.type} name={"input_" + fieldInfo.id} placeholder={fieldInfo.placeholder} required />
        case "email":
            return <input key={fieldInfo.id} className="column small-12 email-field" type={fieldInfo.type} name={"input_" + fieldInfo.id} placeholder={fieldInfo.placeholder} required />
        case "textarea":
            return <textarea key={fieldInfo.id} className="column small-12 textarea-field" type={fieldInfo.type} name={"input_" + fieldInfo.id} placeholder={fieldInfo.placeholder} rows={4} required/>
        default:
            return <br />
    }
}

export default GravityFormField;