import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Slider from "../components/slider"
import LeftContentRightImage from "../components/left-content-right-image"
import StaffDetails from "../components/staff-details"
import SliderCarousel from "../components/slider-carousel"
import GravityFormWithContent from "../components/gravity-form-with-content"
import PageTitle from "../components/title"
import OneColumnTextSection from "../components/one-column-text-section"

const PageTemplate = ({ data }) => {
    return (
        <Layout>
            <div className={data.wordpressPage.slug + " page-slug"}>
                <SEO
                    title={data.wordpressPage.title}
                    description={data.wordpressPage.excerpt}
                />

                <Slider sliders={data.wordpressPage.acf.page_header_page}/>
                {
                    function() {
                        if(data.wordpressPage.acf.page_body_page != null) {
                            return data.wordpressPage.acf.page_body_page.map(single_field => {
                                    switch(single_field.__typename) {
                                        case "WordPressAcf_left_content_right_image": 
                                            return <LeftContentRightImage contentImage={single_field} />;
                                        case "WordPressAcf_staff_details": 
                                            return <StaffDetails staffInfo={single_field} />;
                                        case "WordPressAcf_slider_carousel": 
                                            return <SliderCarousel slidersInfo={single_field} />;
                                        case "WordPressAcf_contact_form_with_content": 
                                            return <GravityFormWithContent formDetails={single_field} />;
                                        case "WordPressAcf_title": 
                                            return <PageTitle titleDetails={single_field} />;
                                        case "WordPressAcf_one_column_text_section": 
                                            return <OneColumnTextSection contentDetails={single_field} />;
                                        default:
                                            return <br />;
                                    }   
                                }
                            )
                        }
                    }()
                }
            </div>
        </Layout>
    )
}
export default PageTemplate

export const query = graphql`
    query($id: Int!) {
        wordpressPage(wordpress_id: { eq: $id }) {
            title
            excerpt
            content
            slug
            acf {
                page_header_page {
                    __typename
                    slide {
                        background_image{
                            localFile {
                                absolutePath
                                publicURL
                                relativePath
                                name
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 4000) {
                                      ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        title
                        subtitle
                        button {
                            title
                            url
                            target
                        }
                    }
                }
                page_body_page {
                    
                    ... on WordPressAcf_left_content_right_image {
                        id
                        content
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 4000) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    ... on WordPressAcf_staff_details {
                        id
                        description
                        name_and_title
                        photo {
                            localFile {
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 500) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    ... on WordPressAcf_slider_carousel {
                        id
                        slide {
                            image {
                                path
                                slug
                                source_url
                                alt_text
                                link
                                localFile {
                                    childImageSharp {
                                        fluid(quality: 100, maxWidth: 1000) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on WordPressAcf_contact_form_with_content {
                        content
                        form_id
                    }
                    ... on WordPressAcf_title {
                        page_title
                    }
                    ... on WordPressAcf_one_column_text_section {
                        content
			background_image {
                            source_url
                            localFile {
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 4000) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allGfForm(filter: {formId: {eq: 1}}) {
            edges {
                node {
                    formId
                    slug
                    apiURL
                    descriptionPlacement
                    formFields {
                        id
                        label
                        labelPlacement
                        description
                        descriptionPlacement
                        type
                        choices
                        content
                        errorMessage
                        inputMaskValue
                        isRequired
                        visibility
                        cssClass
                        placeholder
                        size
                        defaultValue
                        maxLength
                    }
                }
            }
        }
    }
`
