import React from 'react'
import Img from 'gatsby-image'

const LeftContentRightImage = ({contentImage}) => {
    return (
        <div className="left-content-right-image" key={contentImage.id}>
            <div className="left-content-right-image__container row">
                <div className="left-column medium-6 small-12" dangerouslySetInnerHTML={{__html: `${contentImage.content}`}}>
                    
                </div>
                <div className="right-column medium-6 small-12">
                    <Img fluid={contentImage.image.localFile.childImageSharp.fluid} />
                </div>
            </div>
        </div>
    )
}

export default LeftContentRightImage;