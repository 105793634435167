import React from "react"

const PageTitle = ({titleDetails}) => {
    return (
        <div className="page-title row">
            <div className="page-title__container column">
                <h1>{titleDetails.page_title}</h1>
            </div>
        </div>
        
    )
    
}

export default PageTitle;