import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import jQuery from "jquery"

import GravityFormField from "./gravity-form-field"

const GravityForm = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allGfForm(filter: {formId: {eq: 1}}) {
                    edges {
                        node {
                            formId
                            slug
                            apiURL
                            descriptionPlacement
                            formFields {
                                id
                                label
                                labelPlacement
                                description
                                descriptionPlacement
                                type
                                choices
                                content
                                errorMessage
                                inputMaskValue
                                isRequired
                                visibility
                                cssClass
                                placeholder
                                size
                                defaultValue
                                maxLength
                            }
                        }
                    }
                }
            }
        `
    )

    const handleSubmit = (event) => {
        event.preventDefault();
        jQuery('.form-wrapper p').remove();
        const formData = jQuery(".form.form-wrapper").serializeArray()
        jQuery.ajax({
		url: 'https://wordpress.kakoon3.dailypressgroup.org/index.php/wp-json/gf/v2/forms/1/submissions',
            type: 'POST',
            username: "ck_470233dd49b036493b2f4736a0a7050275230c33",
            password: "cs_0cd4e810d24707602213824ce0f1d7620b475605",
            data: formData,
            success: function (response) {
                console.log("Success! " + response)
            },
            error: function (error) {
                console.log("Error! " + error)
            },
            complete: function() {
                jQuery('.form-container').attr('disabled', false);
                
                let message = document.createElement("p");
                message.innerHTML = "Thanks for contacting us! We will get in touch with you shortly.";
                jQuery('.form-wrapper').append(message);
            }
        });
    }

    return (
        <div className="form-container">
            <form className="form form-wrapper" onSubmit={handleSubmit}>
                {data.allGfForm.edges[0].node.formFields.map(single_field => <GravityFormField key={single_field.id} fieldInfo={single_field} />)}
                <button className="button" type="submit">SEND</button>
            </form>
        </div>
    )
}

export default GravityForm
