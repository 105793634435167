import React from 'react'
import GravityForm from './gravity-form'

const GravityFormWithContent = ({formDetails}) => {
    return (
        <div className="gravity-form-with-content">
            <div className="gravity-form-with-content__container row">
                <div className="left-column column small-12 medium-6">
                    <GravityForm  />
                </div>
                <div className="right-column column small-12 medium-6 ">
                    <div dangerouslySetInnerHTML={{__html: `${formDetails.content}`}}></div>
                </div>
            </div>
        </div>
    )
}

export default GravityFormWithContent;