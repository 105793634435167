import React from 'react'
import BackgroundImage from "gatsby-background-image"

const OneColumnTextSection = ({contentDetails}) => {
    return (
        <div className="one-column-text-section">
            <BackgroundImage
                fluid={contentDetails.background_image.localFile.childImageSharp.fluid}
            >
                <div className="one-column-text-section__container row">
                    <div dangerouslySetInnerHTML={{__html: `${contentDetails.content}`}}></div>
                </div>
            </BackgroundImage>
	</div>
    )
}

export default OneColumnTextSection;
