import React from 'react'

const SliderCarousel = ({slidersInfo}) => {
    return (
        <div className="slider-carousel">
            <div className="slider-carousel__container">
                {slidersInfo.slide.map((single_slider, index) => {
                    return (
                        <div className="single-slider" key={index}>
                            {/* {console.log(single_slider.image)} */}
                            <img src={single_slider.image.localFile.childImageSharp.fluid.src} alt={single_slider.image.alt_text}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SliderCarousel;